import React from "react"

import Flickity from "react-flickity-component"

import "./flickity.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./eclair-slider.module.css"

export default function EclairSlider() {
  const { eclairs } = useStaticQuery(graphql`
    {
      eclairs: allEclairsYaml {
        edges {
          node {
            id
            name
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `)

  let flkty = React.createRef()

  const flickityOptions = {
    groupCells: true,
    pageDots: false,
    wrapAround: true,
    prevNextButtons: false,
  }
  return (
    <div className="container">
      <div className={styles.wrapper}>
        <h2 className={styles.sectionTitle}>
          WHAT'S YOUR
          <br />
          FLAVOUR?
        </h2>
        <div className={styles.slider}>
          <Flickity
            flickityRef={ref => (flkty = ref)}
            className={"carousel"} // default ''
            elementType={"div"} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
          >
            {eclairs.edges.map(({ node }) => (
              <article className={styles.eclair} key={node.id}>
                <Img
                  className={styles.image}
                  fluid={node.image.childImageSharp.fluid}
                ></Img>
                <h3 className={styles.title}>{node.name}</h3>
              </article>
            ))}
          </Flickity>
          <button
            className={styles.prev}
            onClick={() => {
              flkty.previous()
            }}
          >
            prev
          </button>
          <button
            className={styles.next}
            onClick={() => {
              flkty.next()
            }}
          >
            next
          </button>
        </div>
      </div>
    </div>
  )
}
