import React from "react"

import styles from "./text-block.module.css"

export default function TextBlock({ children, dark, id }) {
  return (
    <div id={id} className={dark ? styles.dark : null}>
      <div className={`container`}>
        <div className={styles.text}>{children}</div>
      </div>
    </div>
  )
}
