import React from "react"

import logo from "../images/logo.svg"
import arrow from "../images/arrow.svg"

import styles from "./hero.module.css"
import HeroSlider from "./hero-slider"

export default function Hero() {
  return (
    <div className={styles.hero}>
      <HeroSlider></HeroSlider>

      <div className={styles.logo}>
        <div className={styles.logoInner}>
          <img src={logo} alt="Atelier Eclair" />
        </div>
      </div>
      <button
        onClick={() => {
          document.querySelector("#content").scrollIntoView({
            behavior: "smooth", // smooth scroll
            block: "start", // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
          })
        }}
        className={styles.scroll}
      >
        <img src={arrow} alt="Scroll down" />
      </button>
    </div>
  )
}
