import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TextBlock from "../components/text-block"
import EclairGraphic from "../components/eclair-graphic"
import EclairSlider from "../components/eclair-slider"
import Seperator from "../components/seperator"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero></Hero>
    <TextBlock id="content">
      <h2>ATELIER ECLAIR</h2>
      <p>
        Atelier Eclair made by Joriba brings a modern and more refined version
        of the traditional eclair. With this range we want to showcase the
        possibilities for eclairs with a twist, in true tradition of the
        Parisian pastry chefs. We can offer different flavours and sizes, ideal
        for your afternoon high tea moment. The flavours can range from a sweet
        caramel to a dark chocolate or a fresh fruity mango, offering something
        for everyone, or as the French say: A chacun son éclair.
      </p>
    </TextBlock>
    <Seperator></Seperator>
    <EclairSlider></EclairSlider>
    <TextBlock dark>
      <h2>ARTISAN & FROZEN</h2>
      <p>
        Through Atelier Éclair, Joriba is the first to offer frozen eclairs with
        a shelf life of 9 months at -18°C with this type of high-end finish and
        flavour at an affordable price. We bake top quality products and
        directly freeze them. Our customers just need to 'thaw & serve', and
        enjoy their heartfelt moment of indulgence. We specialise in private
        label and together with you, we can make your unique eclair, adapted to
        your market and customer.
      </p>

      <p>
        For more information, contact us at{" "}
        <a href="mailto:info@joriba.be">info@joriba.be</a>.
      </p>
    </TextBlock>
    <EclairGraphic></EclairGraphic>
  </Layout>
)

export default IndexPage
