import React from "react"

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import styles from "./eclair-graphic.module.css"

export default function EclairGraphic() {
  const { image } = useStaticQuery(graphql`
    {
      image: imageSharp(original: { src: { regex: "/eclairs-front/" } }) {
        fluid(maxWidth: 726, maxHeight: 594, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  `)

  return (
    <div className="stripes">
      <Img className={styles.eclairs} fluid={image.fluid}></Img>
    </div>
  )
}
