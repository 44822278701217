import React, { useState, useEffect } from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./hero-slider.module.css"

export default function HeroSlider() {
  const { images } = useStaticQuery(graphql`
    {
      images: allFile(
        filter: { relativeDirectory: { regex: "/heroes/" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const imageCount = images.edges.length

  const [currentIndex, setCurrentIndex] = useState(0)
  const [sliderInterval, setSlideInterval] = useState()

  useEffect(() => {
    resetInterval()
    return clearInterval(sliderInterval)
  }, [currentIndex])

  const resetInterval = () => {
    clearInterval(sliderInterval)
    setSlideInterval(
      setInterval(() => {
        nextSlide()
      }, 5000)
    )
  }

  const nextSlide = () => {
    // check if we need to start over from the first index
    const resetIndex = currentIndex === imageCount - 1
    const index = resetIndex ? 0 : currentIndex + 1

    // assign the logical index to current image index that will be used in render method
    setCurrentIndex(index)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.images}>
        {images.edges.map(({ node }, index) => (
          <div
            key={node.id}
            style={{
              transition: "300ms",
              opacity: index === currentIndex ? 1 : 0,
            }}
          >
            <Img
              style={{
                width: "100%",
                height: "100%",
                transition: "300ms",
              }}
              fluid={node.childImageSharp.fluid}
            ></Img>
          </div>
        ))}
      </div>
      <div className={styles.nav}>
        {images.edges.map(({ node }, index) => (
          <button
            className={`${styles.button} ${
              index === currentIndex ? styles.isActive : null
            }`}
            onClick={() => {
              setCurrentIndex(index)
            }}
            key={`button-${node.id}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  )
}
